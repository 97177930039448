
@import "../../bootstrap/variables.less";

@body-bg:					#000;

@text-color:				#fff;
@link-color:				#f0f;
@link-hover-color:			#fff;
@link-hover-decoration:		underline;

@font-family-base:			Georgia, Times New Roman, Times, serif;
@font-size-base:			13px;
@line-height-base:			1;

@font-size-h1:				20px;
@headings-small-color:		#fff;

@thumb-width:				492px;
@thumb-height:				368px;
@thumb-margin-top:			0;
@thumb-margin-right:		0;
@thumb-margin-bottom:		0;
@thumb-margin-left:			0;
@thumb-border-width:		1px;
@thumb-border:				1px solid #fff;
@thumb-hover-border:		1px solid #fff;

@zindex-popup:				100;
@tooltip-arrow-width:		5px;
@tooltip-bg:				#444;
@popup-text-color:			#fff;
@popup-font-weight:			bold;

@social-links-margin:		5px 0;

@sites-list-font-size:				20px;
@sites-list-color:					#fff;
@sites-list-title-margin-bottom:	5px;
@sites-list-title-size:				18px;
@sites-list-title-weight:			normal;
@sites-list-title-decoration:		none;

@sites-footer-traffictrade-size:	12px;
@sites-footer-traffictrade-margin:	0 0 10px;
@sites-footer-margin:				10px;
@sites-footer-size:					13px;
@sites-footer-color:				#fff;

@parent-alert-padding:		10px;
@parent-alert-margin:		10px auto;
@parent-alert-border:		1px solid #fff;
@parent-alert-font-size:	11px;
@parent-alert-color:		#fff;
@parent-alert-bg:			#8F308F;

@import "common/bootstrap.less";
@import "../../common/utils.less";
@import "../../common/flags-small.less";
@import "../../common/flags.less";
@import "common/modules.less";
@import "common/sites_list.less";
@import "common/sites_footer.less";
@import "common/parent_alert.less";
@import "common/popup.less";
@import "common/mozaique_simple.less";

a {
	text-decoration: underline;
	cursor: pointer;
}
h1 {
	max-width: 960px;
	margin: 0 auto;
	text-align: center;
	color: #fff;
	padding: 2px;
	font-weight: bold;
	a {
		font-size: 14px;
		font-weight: normal;
	}
	.title {
		float: left;
	}
	.address {
		float: right;
	}
}
#language-switcher {
	position: absolute;
	right: 10px;
	top: 0;
}
#ad-footer {
	img,
	video {
		border: 1px solid @link-color;
	}
}
.outro {
	font-size: 18px;
	margin: 18px 0;
	text-align: center;
}
#parent-alert {
	a {
		color: #000;
	}
}